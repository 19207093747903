<app-wl-page-header header="Профиль игрока / привязка к партнёрам"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="searchModel.userId"
            [isSearch]="true"
            placeholder="Логин игрока"
            (searchMethod)="search()"
            (valueChange)="userManipulationsSource.next()"
            (keydown.enter)="$event.target.blur(); search(); (false)"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="searchModel.partnerId"
            [isSearch]="true"
            placeholder="Id партнёра"
            (searchMethod)="search()"
            (valueChange)="userManipulationsSource.next()"
            (keydown.enter)="$event.target.blur(); search(); (false)"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-text
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="searchModel.userHash"
            [isSearch]="true"
            placeholder="Хэш игрока"
            (searchMethod)="search()"
            (valueChange)="userManipulationsSource.next()"
            (keydown.enter)="$event.target.blur(); search(); (false)"
    >
    </app-wl-strict-input-text>
</div>
<div *ngIf="isShowPrompt">
    <div> Для отображения привязки партнёров к игроку - вводить логин игрока</div>
    <div> Для отображения профиля игрока - вводить (логин игрока + id партнёра) / хэш игрока</div>
</div>

<div *ngIf="!!tableData && result?.userPartners?.length > 0">
    <h4 class="wl-span-header-text">Привязка пользователя к партнёрам:</h4>
<ga-table [data]="tableData" [breakWordOnly]="true"></ga-table>
</div>

<div *ngIf="result?.partnerUserProfileInfo">
    <h4 class="wl-span-header-text">Общая информация игрока: </h4>
    <div class="wl-userinfo-container">
        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Id игрока: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.id }}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Id партнёра: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.partnerId}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата регистрации: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.registrationDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата идентификации: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.identificationDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата первого депозита: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.firstDepositDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата первого депозита после привязки партнёра: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.fitstDepositAfterbindDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата реактивационного депозита: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.reactivatedDepositDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата последнего депозита: </span>
            <span class="wl-userinfo-field-value">
             {{result.partnerUserProfileInfo.lastDepositDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата первой ставки: </span>
            <span class="wl-userinfo-field-value">
             {{result.partnerUserProfileInfo.firstBetDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата изменения статуса CRM = 0: </span>
            <span class="wl-userinfo-field-value">
             {{result.partnerUserProfileInfo.crm0Date | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата изменение статуса РФ и 18+ legal: </span>
            <span class="wl-userinfo-field-value">
             {{result.partnerUserProfileInfo.legalDate | wlDateTime}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Id Btag: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.bTagId}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Id Promo: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.promoGroupId}}
        </span>
        </div>

        <div class="wl-user-info-row">
            <span class="wl-userinfo-field-label">Дата последнего обновления данных: </span>
            <span class="wl-userinfo-field-value">
            {{result.partnerUserProfileInfo.lastUpdate | wlDateTime}}
        </span>
        </div>
    </div>
</div>


<h4 *ngIf="result?.tasksInfo" class="wl-span-header-text">Подписки на бук. задания: </h4>
<div *ngIf="result?.tasksInfo && result?.tasksInfo?.length < 1" class="wl-userinfo-container"> Отсутствуют подписки на бук. задания у выбранного партнёра</div>

<div *ngIf="result?.tasksInfo?.length > 0">
    <ga-table *ngIf="!!taskInfoTableData" [data]="taskInfoTableData" [breakWordOnly]="true"></ga-table>
</div>
