import { AbstractModelValidator,  StrictError } from '@koddington/ga-common';
import { GamblerBindingSearchModel } from '../models/gambler-binding-search-model';

export class TrackerGamblerBindingProfileValidator extends AbstractModelValidator<GamblerBindingSearchModel> {
    constructor() {
        super();
        this.ruleForControlIf(u => u.userHash, u =>
            u.partnerId.hasStrictValue && u.userId.hasStrictValue && u.userHash?.value?.length > 0)
            .customRule((_, model) => {
                return new StrictError('GamblerBindingProfileError1', 'Одновременный поиск по Хэш-коду и (id пользователя + id партнёра) запрещен');
            });
    }
}
